import { initial } from "@/api/app";
import { useStateStore } from "@/stores/app/state";
import { ref } from "vue";

export default () => {
  const isFetching = ref(false);
  const stateStore = useStateStore();

  const success = async (response) => {
    if (response.status != 200) {
      return false;
    }

    if(response.data.count) {
      stateStore.setCount(response.data.count);
    }

    if(response.data.deliveries) {
      stateStore.setDeliveries(response.data.deliveries);
    }

    if(response.data.products) {
      stateStore.setProducts(response.data.products);
    }

    if(response.data.cities) {
      stateStore.setCities(response.data.cities);
    }

    if(response.data.rates) {
      stateStore.setRates(response.data.rates);
    }
  };

  const failed = async (error) => {
    console.log(error);
  };

  const execute = async () => {
    isFetching.value = true;
    stateStore.setFetched(false);
    await initial().then(success, failed);
    stateStore.setFetched(true);
    isFetching.value = false;
  };

  return {
    execute,
    isFetching,
  };
};
