<template>
    <tr :key="item.id"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[120px] tw-truncate">{{ item.product.name }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[70px] tw-truncate tw-capitalize">{{ item.platform }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[70px] tw-truncate">{{ item.spend }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[120px] tw-truncate">{{ item.started_at }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-w-[120px] tw-truncate">{{ item.stopped_at }}</p>
        </td>

        <td class="tw-px-5 tw-pr-0 tw-py-">
            <AdTableActions :item="item" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRef} from "vue";
import AdTableActions from "./AdTableActions";
const props = defineProps(["item"]);
const item = toRef(props, "item");

</script>

<style></style>