import DashboardLayout from '@/layouts/dashboard/DashboardLayout'

import AdminDashboardView from '@/views/admin/dashboard/DashboardView'
import AgentDashboardView from '@/views/agent/dashboard/DashboardView'
import FollowupDashboardView from '@/views/followup/dashboard/DashboardView'

export default [
    {
        path: '/dashboard',
        component: DashboardLayout,
        meta: {
            middleware: [ 'auth' ]
        },
        children: [
            {
                name: 'pages.dashboard.admin',
                path: 'admin',
                component: AdminDashboardView,
                meta: {
                    middleware: [ 'auth' ],
                    roles: [ 'admin' ]
                }
            },
            {
                name: 'pages.dashboard.agent',
                path: 'agent',
                component: AgentDashboardView,
                meta: {
                    middleware: [ 'auth' ],
                    roles: [ 'agent' ]
                }
            },
            {
                name: 'pages.dashboard.followup',
                path: 'followup',
                component: FollowupDashboardView,
                meta: {
                    middleware: [ 'auth' ],
                    roles: [ 'followup' ]
                }
            },
        ]
        
    }
]