<template>
    <div>
        <div :class="[ index > 0 && '!tw-border-purple-500 tw' ]" class=" tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-overflow-hidden">

            <div :class="[ index > 0 && '!tw-bg-purple-50 tw-border-purple-500' ]" class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-p-2 tw-bg-gray-50 tw-border-solid">
                <div class="tw-flex tw-items-center tw-gap-5">
                    <h1 
                        class="tw-text-lg tw-flex tw-items-center tw-gap-2 tw-font-bold tw-text-gray-600 ">
                        <span>{{ $t('common.order') }}</span>
                        <span class="tw-text-primary-500">{{ order.id }}</span>
                    </h1>
                    <a-button intent="purple" v-if="index > 0" right-icon="humbleicons:exclamation" class="!tw-py-0 tw-min-h-[30px]">
                        {{ $t('buttons.order-is-duplicated') }}
                    </a-button>

                </div>

                <div class="tw-flex tw-items-center tw-gap-2">
                    <div>

                        <p class="tw-truncate tw-max-w-[150px] tw-text-base tw-px-4 tw-rounded-sm tw-w-fit tw-py-0.5 tw-mx-auto" :class="[confirmation.color]" v-if="confirmation?.name">
                            {{ $t(confirmation.name) }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="tw-p-4 tw-py-4 tw-flex tw-flex-col tw-gap-2">
                <div>
                    <label class="tw-text-xs tw-text-gray-500">{{ $t('fields.customer_name') }}</label>
                    <p class="tw-text-lg tw-font-semibold">{{ order.customer_name }}</p>
                </div>
                <div>
                    <label class="tw-text-xs tw-text-gray-500">{{ $t('fields.customer_phone') }}</label>
                    <p class="tw-text-lg tw-font-semibold">{{ order.customer_phone }}</p>
                </div>
                <div>
                    <label class="tw-text-xs tw-text-gray-500">{{ $t('fields.customer_city') }}</label>
                    <p class="tw-text-lg tw-font-semibold">{{ order.customer_city }}</p>
                </div>
                <div>
                    <label class="tw-text-xs tw-text-gray-500">{{ $t('fields.customer_address') }}</label>
                    <p class="tw-text-lg tw-font-semibold">{{ order.customer_address }}</p>
                </div>
                <div class="">
                    <label class="tw-text-xs tw-text-gray-500">{{ $t('fields.order_summary') }}</label>
                    <div class="tw-flex tw-gap-2" v-for="i in order.items" :key="i.id + ' ' + order.updated_at"> 
                        <span class="tw-text-primary-500 tw-font-bold">{{ i.quantity }} x </span>
                        <span class="tw-font-semibold">{{ i.product_name }}</span>
                    </div>
                    <p class="tw-text-lg tw-font-semibold"></p>
                </div>
            </div>

            <order-card-actions :order="order" :index="index" />
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, toRef, computed } from 'vue';
import { getConfirmation } from "@/utils/status";
import OrderCardActions from './OrderCardActions'

const props = defineProps({
    order: {
        required: true
    },
    index: {
        required: false
    }
});

const emit = defineEmits(['update']);
emit
const order = toRef(props, 'order');
const index = toRef(props, 'index');
const confirmation = computed(() => getConfirmation(order.value.agent_status))

</script>

<style></style>