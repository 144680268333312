<template>
  <v-navigation-drawer v-model="isActive" class="!tw-fixed !tw-top-0 tw-min-h-screen" :absolute="false" :location="$root.$i18n.locale == 'ar' ? 'right' : 'left'">
      
    <v-list-item class="!tw-overflow-visible tw-border-b">
      <div class="tw-p-2">
        <AppLogo />
      </div>
    </v-list-item>
    <v-list class="tw-space-y-1 tw-px-2">
        <v-list-item class="!tw-min-h-0 tw-text-xs tw-px-2 tw-font-bold tw-mt-1 tw-text-gray-500 tw-uppercase">
          {{ $t('dashboard.sidebar.menu') }}
        </v-list-item>

        <v-list-item v-for="link in links" :key="link.id" :to="{name: link.to}" :active="false" :ripple="false" 
        :class="[$route.name == link.to && '!tw-bg-primary-100/50 !tw-text-black']"
        class="!tw-overflow-visible !tw-px-1 tw-bg-white tw-text-gray-800 !tw-min-h-[35px] tw-rounded-lg" link>
          <div class="tw-relative tw-z-10  tw-p-0.5  tw-flex tw-flex-row tw-items-center tw-gap-3">
            <div 
            :class="[$route.name == link.to && '!tw-border-primary-200 !tw-border-b-primary-500/50']"
            class="tw-border tw-border-b-2 tw-border-gray-200 tw-p-1 tw-bg-white tw-rounded-md">
              <icon 
              :class="[$route.name == link.to && '!tw-text-primary-500']"
              class="tw-text-gray-500 tw-text-lg" :icon="$route.name == link.to ? link.icon.enabled : link.icon.disabled" />
            </div>
            <span 
            :class="[$route.name == link.to && '!tw-text-black !tw-font-semibold']"
            class="tw-font-semibold tw-text-gray-600 tw-text-sm">{{ $t(link.name) }}</span>

            <template v-if="link.name == 'dashboard.sidebar.orders'">
              <div v-if="stateStore.fetched" class="tw-font-semibold tw-text-xs tw-px-2 tw-py-0.5 tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-500 tw-text-white">
                {{ stateStore.count.orders }}
              </div>
              <div v-if="!stateStore.fetched" class="tw-font-semibold tw-text-xs tw-px-2 tw-py-0.5 tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-500 tw-text-white">
                <icon icon="eos-icons:three-dots-loading" class="tw-text-white tw-text-sm" />
              </div>
            </template>

            <template v-if="link.name == 'dashboard.sidebar.my-orders' && $user.role == 'agent'" >
              <div v-if="stateStore.fetched" class="tw-font-semibold tw-text-xs tw-px-2 tw-py-0.5 tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-500 tw-text-white">
                {{ stateStore.count.orders }}
              </div>
              <div v-if="!stateStore.fetched" class="tw-font-semibold tw-text-xs tw-px-2 tw-py-0.5 tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-500 tw-text-white">
                <icon icon="eos-icons:three-dots-loading" class="tw-text-white tw-text-sm" />
              </div>
            </template>

            <template v-if="link.name == 'dashboard.sidebar.confirm-orders' && $user.role == 'agent'">
              <div v-if="stateStore.fetched && stateStore.count.new" class="tw-font-semibold tw-text-xs tw-gap-1 tw-px-2 tw-py-0.5 tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-rose-500 tw-text-white">
                <span>{{ stateStore.count.new }}</span>
                <span  class=" tw-text-[10px]">{{ $t('common.new') }}</span>
                

              </div>
              <div v-if="!stateStore.fetched" class="tw-font-semibold tw-text-xs tw-px-2 tw-py-0.5 tw-h-[20px] tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-rose-500 tw-text-white">
                <icon icon="eos-icons:three-dots-loading" class="tw-text-white tw-text-sm" />
              </div>
            </template>

          </div>
        </v-list-item>

        <v-list-item class="!tw-min-h-0 tw-text-xs tw-px-2 tw-font-bold tw-text-gray-500 tw-uppercase !tw-mt-4">
          {{ $t('dashboard.sidebar.support') }}
        </v-list-item>

        <v-list-item :to="{name: 'products'}" :active="false" :ripple="false" 
        :class="[$route.name == 'products' && '!tw-bg-primary-100/50 !tw-text-black']"
        class="!tw-overflow-visible !tw-px-1 tw-bg-white tw-text-gray-800 !tw-min-h-[35px] tw-rounded-lg" link>
          <div class="tw-relative tw-z-10  tw-p-0.5  tw-flex tw-flex-row tw-items-center tw-gap-3">
            <div 
            :class="[$route.name == 'products' && '!tw-border-primary-200 !tw-border-b-primary-500/50']"
            class="tw-border tw-border-b-2 tw-border-gray-200 tw-p-1 tw-bg-white tw-rounded-md">
              <icon 
              :class="[$route.name == 'products' && '!tw-text-primary-500']"
              class="tw-text-gray-500 tw-text-lg" :icon="$route.name == 'products' ? 'solar:help-bold' : 'solar:help-bold-duotone'" />
            </div>
            <span 
            :class="[$route.name == 'products' && '!tw-text-black !tw-font-semibold']"
            class="tw-font-semibold tw-text-gray-600 tw-text-sm">{{ $t('dashboard.sidebar.help-center') }}</span>
          </div>
        </v-list-item>



      </v-list>
    </v-navigation-drawer>
</template>

<script setup>
import allLinks from '@/data/links.json'
import AppLogo from '@/components/AppLogo.vue';
import { useAuthStore } from '@/stores';
import { defineProps, defineEmits, computed } from 'vue';
import { useStateStore } from '@/stores/app/state';

const stateStore = useStateStore();
const authStore = useAuthStore();
const emit = defineEmits(['update:drawer'])
const props = defineProps({
  drawer: {
    required: true
  }
})

const links = computed(() => authStore.user.role in allLinks ? allLinks[authStore.user.role].filter(l => l.active) : [])
// const links = allLinks.admin.filter(l => l.active)
const isActive = computed({
  get: () => props.drawer,
  set: (v) => emit('update:drawer', v)
})



</script>

<style>

</style>