<template>
    <a-button @click="onCall($user.role)" as="a" :href="'tel:' + props.order.customer_phone" intent="info" left-icon="solar:phone-bold-duotone"
        class="tw-min-w-[100px]">
        {{ $t("buttons.call") }}
    </a-button>
</template>

<script setup>
import { call } from '@/api/orders';
import { defineProps, ref } from 'vue';

const props = defineProps(['order']);
const called = ref(false);
called

const onCall = (role) => {
    call(props.order.id, role == 'admin' ? 'agent' : role)
    .then(
        res => {
            console.log(res.data)
        }
    )
}
</script>

<style>

</style>