
import MyOrdersView from "@/views/followup/orders/MyOrdersView";

export default [
    {
        name: "pages.followup.my-orders",
        path: "my-orders",
        component: MyOrdersView,
        meta: {
          middleware: ["auth"],
        },
      },
]