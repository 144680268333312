<template>
    <div class="tw-h-[100px] tw-bg-gray-200x">
        <apexchart :key="'chart'" class="-tw-translate-y-[20px]" type="rangeBar" height="100" :options="chartOptions" :series="series" />
    </div>
</template>

<script setup>
import moment from "moment";
import { defineProps 
} from "vue";
import { toRef } from "vue";

const props = defineProps(['day', 'item', 'data'])
const data = toRef(props, 'data')
// Convert datetime strings to timestamps for ApexCharts
const processData = (data) => {
    return data.value.map((entry) => ({
        x: moment(entry.login_time).format('YYYY-MM-DD').toString(), // Displayed day on Y-axis
        // x: entry.login_time, // Displayed day on Y-axis
        y: [
            new Date(entry.login_time).getTime(), // Start time (timestamp in ms)
            new Date(entry.logout_time).getTime(), // End time (timestamp in ms)
        ],
    }));
};

const series = [
    {
        name: "User Activity",
        data: processData(data),
    },
];

const chartOptions = {
    chart: {    
        type: "rangeBar",
        toolbar: { show: false },
        sparkline: {
            enabled: false
        }
        // grid: {
        //     show: true,
        //     padding: { left: -10, right: -10, top: 0, bottom: 0 },
        // },
    },
    grid: {
        padding: {
            left: -10,
            right: -10,
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: "100%",
        },
    },
    xaxis: {
        type: "datetime", // X-axis needs to be datetime for correct time display
        labels: {
            format: "HH:mm", // Format X-axis labels to show time
            show: true,
        },
    },
    yaxis: {
        labels: {
            formatter: (val) => new Date(val).toLocaleDateString("en-US", {
                weekday: "short",
                month: "numeric",
                day: "numeric",
            }),
            show: false,
        },
    },
    tooltip: {
        x: {
            format: "yyyy-MM-dd HH:mm:ss", // Tooltip format for the time
        },
        y: {
            formatter: (v) => moment(v).format('HH:mm:ss'), // Custom text in the tooltip
        },
    },
    fill: {
        opacity: 0.6,
    },
    colors: ["#00E396"],
};



// const calculateTotalTime = () => {
//   let totalMinutes = 0;

//   data.value.forEach(entry => {
//     const loginTime = moment(entry.login_time, 'YYYY-MM-DD HH:mm:ss');
//     const logoutTime = moment(entry.logout_time, 'YYYY-MM-DD HH:mm:ss');
//     const duration = moment.duration(logoutTime.diff(loginTime));
//     totalMinutes += duration.asMinutes();
//   });

//   return totalMinutes;
// };

// // Reactive reference for total time
// const totalTime = computed(() => {
//   const minutes = calculateTotalTime();
//   const hours = Math.floor(minutes / 60);
//   const mins = Math.floor(minutes % 60);
//   return `${hours}h ${mins}min`;
// });

</script>