<template>
  <v-layout v-if="authStore.isLoggedIn" :dir="$root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :full-height="true"
    class="rounded rounded-md">

    <dashboard-sidebar v-model:drawer="drawer" />

    <dashboard-header v-model:drawer="drawer" />


    <v-main class="tw-min-h-screen tw-h-fit tw-overflow-y-auto tw-bg-gray-100">
      <router-view></router-view>
    </v-main>
  </v-layout>
</template>

<script setup>
import { ref } from "vue";
import DashboardHeader from "./partials/DashboardHeader.vue";
import DashboardSidebar from "./partials/DashboardSidebar.vue";
import { useAuthStore, useUserStore } from "@/stores";
import initial from "@/services/app/initial";
import { useI18n } from "vue-i18n";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { BACKEND_URL } from "@/config/app";


const i18n = useI18n();
const drawer = ref(true);
const authStore = useAuthStore();
const userStore = useUserStore();

const { execute } = initial();

if (i18n.locale.value == 'ar') {
  document.querySelector('html').setAttribute('dir', 'rtl');
}


// get required data for the app
execute();
Pusher.logToConsole = true;

Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

if(!undefined) {
  
  window.Pusher = Pusher
  const echo = new Echo({
    broadcaster: "pusher",
    key: '7c28aa380f928f493798',
    cluster: 'eu',
    channelAuthorization: {
      // transport: "jsonp",
      endpoint: BACKEND_URL + "api/pusher",
      withCredentials: true,
    },
  })
  
  const channelId = 1;
  echo.join(`channel.${channelId}`)
  .here((members) => {
    console.log('members: ', members)
    userStore.setOnline(members);
  })
  .joining((member) => {
    console.log('member: ', member)
    userStore.online.push(member)
  })
  .leaving((member) => {
    console.log('member: ', member)
    userStore.setOnline(userStore.online.filter(m => m.name != member.name));
  })
  // .listen('YourEvent', (e) => {
  //     console.log(e);
  // });

}

</script>
