<template>
    <div class="tw-w-full tw-text-center tw-flex tw-justify-end tw-gap-2 tw-px-4">
        
        <UserEditPopup :item="props.item" />

        <UserTableActionsTimeTracking :item="props.item" />


        <a-action-button @click="visible.delete = true" icon="solar:trash-bin-2-bold-duotone" intent="danger"></a-action-button>
        <delete-modal :visible="visible.delete" :loading="loading.delete" @confirm="onDeleteConfirmed" @cancel="visible.delete = false" />
    </div>
</template>

<script setup>
import { defineProps,ref, inject } from 'vue';
import UserEditPopup from '../UserEditPopup';
import { destroy } from '@/api/users';
import { useI18n } from "vue-i18n";
import useAlert from "@/composables/useAlert";
import DeleteModal from "@/components/modals/DeleteModal";
import UserTableActionsTimeTracking from './actions/UserTableActionsTimeTracking.vue';


const i18n = useI18n()

const props = defineProps({
    item: {
        type: Object,
        required: true
    }
})

const visible = ref({
    delete: false
});
const loading = ref({
    delete: false
})
const onDeleted = inject('onDeleted')

const onDeleteConfirmed = async () => {
    loading.value.delete = true;
    await destroy(props.item.id)
        .then(
            r => {
                if(r.data.code == 'SUCCESS') {
                    useAlert(i18n.t('messages.deleted-successfully', { field: i18n.t('fields.user') }))
                    onDeleted(props.item.id)
                    visible.value.delete = false;
                }
            },
            error => {
                console.log(error)
            }
        )
    loading.value.delete = true;
}

</script>

<style></style>