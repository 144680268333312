<template>
    <a-popup :visible="visible" @cancel="onCancel">
        <div
            class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px]  tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
            <div>
                <div
                    class="tw-p-4 tw-text-lg tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-solid tw-font-semibold dark:tw-text-gray-700 tw-text-gray-500 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <span>{{ $t("common.order") }}</span>
                    <span class="tw-text-primary-500">{{ props.item.id }}</span>
                </div>

                <div v-if="loading" class="tw-bg-white tw-p-5 tw-flex tw-flex-col tw-gap-5 tw-py-10">
                    <div class="tw-h-[20px] tw-w-full tw-bg-gray-100 tw-animate-pulse tw-rounded"></div>
                    <div class="tw-h-[40px] tw-w-full tw-bg-gray-100 tw-animate-pulse tw-rounded"></div>
                    <div class="tw-h-[20px] tw-w-full tw-bg-gray-100 tw-animate-pulse tw-rounded"></div>
                    <div class="tw-h-[40px] tw-w-full tw-bg-gray-100 tw-animate-pulse tw-rounded"></div>
                    <div class="tw-h-[20px] tw-w-full tw-bg-gray-100 tw-animate-pulse tw-rounded"></div>
                </div>

                <div v-else class="tw-py-10 tw-px-4 tw-bg-white tw-flex tw-flex-col tw-gap-2">
                    <div v-for="h in data" :key="h.id" class="tw-border tw-border-gray-200 tw-rounded">
                        <div class="tw-flex tw-items-center tw-justify-between tw-p-2 tw-bg-gray-200">
                            <div class="tw-flex tw-items-center tw-gap-2 tw-text-xs tw-uppercase tw-font-semibold">
                                <icon icon="solar:user-rounded-bold-duotone" class="tw-text-xl tw-text-gray-700" />
                                <p>{{ h.action_by }}</p>
                            </div>
                            <div class="tw-text-xs tw-font-medium" @mouseenter="h.hovering = true"
                                @mouseleave="h.hovering = false">
                                <p v-if="h.hovering">{{ moment(h.created_at).format('MMMM Do, YYYY [at] h:mm A') }}</p>
                                <p v-else>{{ moment(h.created_at).locale($i18n.locale).fromNow() }}</p>
                            </div>
                        </div>

                        <div class="tw-p-2x tw-border-tx tw-border-solid tw-border-gray-200">
                            <div class="tw-rounded tw-borderx tw-border-solid tw-border-gray-100 tw-overflow-autox">
                                <table class="tw-min-w-full tw-leading-normal tw-w-full">
                                    <thead class="">
                                        <tr>
                                            <th v-for="c in columns" :key="c.name" :class="c.classes"
                                                class="tw-px-2 tw-text-[10px] last:tw-border-e-0 tw-whitespace-nowrap tw-border-e tw-py-1 tw-border-b tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-font-semibold tw-text-gray-600 tw-uppercase tw-tracking-wider">
                                                {{ $t(c.label) }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="f.field" v-for="f in h.fields"
                                            class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
                                            <td class="tw-text-xs tw-px-2 tw-py-1 tw-font-semibold">{{ $t('fields.' + f.field) }}</td>

                                            <template v-if="f.field == 'agent_status' || f.field == 'followup_status'">
                                                <td class="tw-text-xs tw-px-2 tw-py-1 tw-text-amber-700">{{ $t( 'order-status.confirmation.' + f.old_value) }}</td>
                                                <td class="tw-text-xs tw-px-2 tw-py-1 tw-text-green-700">{{ $t( 'order-status.confirmation.' + f.new_value) }}</td>
                                            </template>
                                            <template v-else-if="f.field == 'delivery_status'">
                                                <td class="tw-text-xs tw-px-2 tw-py-1 tw-text-amber-700">{{ $t( 'order-status.delivery.' + f.old_value) }}</td>
                                                <td class="tw-text-xs tw-px-2 tw-py-1 tw-text-green-700">{{ $t( 'order-status.delivery.' + f.new_value) }}</td>
                                            </template>
                                            <template v-else>
                                                <td class="tw-text-xs tw-px-2 tw-py-1 tw-text-amber-700">{{ f.old_value }}</td>
                                                <td class="tw-text-xs tw-px-2 tw-py-1 tw-text-green-700">{{ f.new_value }}</td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-popup>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { history } from "@/api/orders";
import moment from 'moment';

const emit = defineEmits(['update:visible']);
const loading = ref(false);
const data = ref([]);
const columns = ref([
    { name: 'field', label: 'fields.field', classes: ['tw-w-[100px]'] },
    { name: 'old_value', label: 'fields.old_value', classes: [] },
    { name: 'new_value', label: 'fields.new_value', classes: [] },
])

const props = defineProps({
    item: {
        required: true
    },
    visible: {
        required: true,
        default: false
    }
});

const onCancel = () => {
    emit('update:visible', false)
}

const getData = async () => {
    loading.value = true;
    await history(props.item.id)
        .then((res) => {
            if (res.data.code == 'SUCCESS') {
                data.value = res.data.data
                data.value.reverse();
            }
        })
    loading.value = false;
}

getData();


</script>

<style></style>