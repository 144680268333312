<template>
    <div>
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-2 tw-mb-2">
            <div v-for="widget in widgets" :key="widget"
                class="tw-p-5 tw-h-[120px] tw-bg-white tw-rounded tw-shadow-smx tw-shadow-gray-300/50 tw-border tw-border-solid tw-border-gray-200">
                <p class="tw-text-lg tw-text-gray-500">{{ $t(widget.name) }}</p>
                <div v-if="!loading" class="tw-flex tw-items-center tw-gap-1 tw-mt-2">
                    <p class="tw-text-2xl tw-font-bold tw-text-primary-500">{{ new Intl.NumberFormat().format(widget.value) }}</p>
                    <p v-if="widget.key == 'earnings'" class="tw-text-lg tw-font-bold tw-text-primary-500">{{ $t('currencies.' + CURRENCY, 'USD') }}</p>
                </div>
                <p v-if="loading" class="tw-mt-2 tw-text-5xl tw-font-bold tw-text-primary-500"><icon icon="eos-icons:three-dots-loading" /></p>
            </div>


        </div>
    </div>
</template>

<script setup>
import { admin } from '@/api/dashboard';
import { CURRENCY } from '@/config/app';
import { ref } from 'vue';

const loading = ref(true)

const widgets = ref([
    { key: 'orders', name: 'pages.dashboard.total-orders', icon: 'solar:bag-5-bold-duotone', class: ['tw-text-emerald-500'], value: 0 },
    { key: 'earnings', name: 'pages.dashboard.earnings', icon: 'solar:bag-5-bold-duotone', class: ['tw-text-emerald-500'], value: 0 },
    { key: 'confirmed', name: 'common.confirmed', icon: 'solar:bag-5-bold-duotone', class: ['tw-text-emerald-500'], value: 0 },
    { key: 'delivered', name: 'common.delivered', icon: 'solar:bag-5-bold-duotone', class: ['tw-text-emerald-500'], value: 0 },
])

const getData = async () => {
    loading.value = true; 
    await admin.overview()
    .then(
        res => {
            console.log(res.data)
            widgets.value[0].value = res.data.data.orders
            widgets.value[1].value = res.data.data.revenue
            widgets.value[2].value = res.data.data.confirmed
            widgets.value[3].value = res.data.data.delivered
        }
    )
    .catch(
        err => {
            console.log(err)
        }
    )
    loading.value = false
}

getData()
</script>

<style></style>