<template>
  <tr
    :key="item.id"
    class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100"
  >
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">
      <button @click="copy(item.sku)" title="Copy SKU" class="tw-p-1 tw-bg-gray-50 tw-border tw-border-solid tw-border-transparent hover:tw-border-gray-200 tw-duration-200 hover:tw-bg-gray-100 tw-rounded tw-text-xs tw-w-fit tw-px-2 tw-flex tw-items-center tw-gap-2 tw-text-gray-500">
        <span>{{ item.sku }}</span>
      </button>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">
      <p class="tw-max-w-[120px] tw-truncate tw-whitespace-nowrap">{{ item.name }}</p>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.quantity }}</td>
    <td class="tw-px-5 tw-pr-0 tw-py-">
        <ProductTableActions />
    </td>
  </tr>
</template>

<script setup>
import { defineProps, toRef, provide } from 'vue';
import ProductTableActions from './ProductTableActions';
import useAlert from '@/composables/useAlert';
import { useI18n } from 'vue-i18n';

const props = defineProps(['item']);
const item = toRef(props, 'item');
const i18n = useI18n()

const copy = (value) => {
  navigator.clipboard.writeText(value);
  useAlert(i18n.t('messages.copied-to-clipboard'))
}

provide('product', item)

</script>

<style></style>
