import {
    server
} from "@/api";


export const create = async (sheet) => {
    return await server().post('api/sheets', sheet)
}

export const destroy = async (id) => {
    return await server().delete(`api/sheets/${id}`)
}

export const refresh = async (id) => {
    return await server().get(`api/sheets/${id}/sync`);
}

export const paginate = async (params) => {
    return await server().get('api/sheets', { params });
}

export const update = async (id, sheet) => {
    return await server().post(`api/sheets/${id}`, sheet);
}

export default {
    create,
    paginate,
    update,
    refresh,
    destroy
}