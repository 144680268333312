<template>
    <div class="tw-mt-2 tw-p-2 tw-bg-white tw-rounded-sm tw-border tw-border-solid tw-border-gray-200">
        <div class="tw-px-2 tw-border-s-2 tw-border-solid tw-border-primary-500">
            <h1 class="tw-text-sm tw-font-bold tw-text-gray-700">{{ $t('common.online') }}</h1>
        </div>

        <div v-if="online.length" class="tw-flex tw-gap-2 tw-mt-4">
            <div class="tw-p-2 tw-py-1 tw-bg-white tw-rounded-sm tw-border tw-border-solid tw-border-b-2x tw-border-gray-300 tw-flex tw-items-center tw-gap-2"
                v-for="o in online" :key="o">
                <icon icon="lets-icons:user-duotone" class="tw-text-xl tw-text-gray-500x tw-text-emerald-600" />
                <p class="tw-text-sm">{{ o.name }}</p>
            </div>
        </div>

        <div v-if="!online.length" class="tw-flex tw-gap-2 tw-mt-4">
            <div class="tw-w-full tw-p-4 tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-200">
                <p class="tw-text-sm">{{ $t('common.no-online-users') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAuthStore, useUserStore } from '@/stores';
import { computed } from 'vue';

const userStore = useUserStore();
const authStore = useAuthStore()

const online = computed(() => userStore.online.filter(u => u.id != authStore.user?.id))
</script>

<style></style>