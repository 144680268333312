import AdListView from "@/views/admin/ads/AdListView";

export default [
  {
    name: "pages.admin.ads",
    path: "ads",
    component: AdListView
  },
  
];
