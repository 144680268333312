import { createRouter, createWebHistory } from 'vue-router'

// Middlewares
import authMiddleware from '@/middlewares/auth';
import guestMiddleware from '@/middlewares/guest';

// Views
import DashboardLayout from '@/layouts/dashboard/DashboardLayout'
import HomeView from '../views/HomeView.vue'
import ProductsView from '@/views/ProductsView'
import OrdersView from '@/views/OrdersView'
import SettingsView from '@/views/SettingsView'
import ShopsView from '@/views/ShopsView'
import UsersView from '@/views/UsersView'

// Routes
import auth from './routes/auth'
import dashboard from './routes/dashboard'

// Routes by role
import admin from './routes/admin'
import agent from './routes/agent'
import followup from './routes/followup'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'dashboard' }
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    meta: {
      middleware: ['auth']
    },
    children: [
      {
        name: 'dashboard',
        path: '',
        component: HomeView
      },
      {
        name: 'orders',
        path: 'orders',
        component: OrdersView
      },
      {
        name: 'shops',
        path: 'shops',
        component: ShopsView
      },
      {
        name: 'products',
        path: 'products',
        component: ProductsView
      },
      {
        name: 'users',
        path: 'users',
        component: UsersView
      },
      {
        name: 'settings',
        path: 'settings',
        component: SettingsView
      },
    ]
  },

  ...auth,
  ...dashboard,
  ...admin,
  ...agent,
  ...followup
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(guestMiddleware);
router.beforeEach(authMiddleware);

export default router
