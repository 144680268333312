<template>
    <div>
        <p class="tw-text-sm tw-mb-1">{{ $t('common.treated_at') }}</p>
        <div class="tw-p-2 tw-border tw-border-solid tw tw-border-gray-200 tw-px-4">
        <FormField v-model="field.from" :label="$t('fields.from')" type="date" :error="errors.treated_at &&
                $t('errors.' + errors.treated_at, {
                    field: $t('fields.from'),
                })
                ">

        </FormField>

        <FormField v-model="field.to" :label="$t('fields.to')" type="date" :error="errors.treated_at &&
                $t('errors.' + errors.treated_at, {
                    field: $t('fields.to'),
                })
                ">
        </FormField>
    </div>
    </div>
</template>

<script setup>
import FormField from "@/components/form/FormField";
import { ref, watch, inject } from 'vue';
// import { useI18n } from "vue-i18n";

// const i18n = useI18n();
const updateFilters = inject('update:filters')
const filters = inject('filters')

const field = ref({
    from: null,
    to: null
})
const errors = ref({})

watch(() => field.value, () => {
    const newFilters = {
        ...filters.value,
        treated_at: field.value,
    }

    updateFilters(newFilters)
}, { deep: true })

</script>

<style></style>