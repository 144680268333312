<template>
    <div :class="[index > 0 && '!tw-bg-purple-50 tw-border-purple-500']"
        class="tw-flex tw-justify-end tw-gap-2 tw-p-2 tw-bg-gray-50 tw-border-t tw-border-solid tw-border-gray-200">
        
        <EditButton :item="props.order" />

        <ActionButtonCall :order="props.order" />
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import EditButton from './actions/EditButton';
import ActionButtonCall from "@/components/orders/partials/ActionButtonCall";

const props = defineProps(['order', 'index']);
const emit = defineEmits(['update'])
emit


</script>

<style></style>