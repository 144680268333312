<template>
    <div ref="container" class="tw-relative">
        <button @click="isActive = !isActive" class="tw-flex tw-border-b-2 active:!tw-border-b tw-duration-200 active:tw-translate-y-0.5 tw-items-center tw-justify-center tw-border tw-border-solid tw-border-gray-200 tw-rounded-md tw-w-[35px] tw-h-[35px]">
            <icon icon="solar:earth-linear" class="tw-text-xl tw-text-gray-600" />
        </button>

        <transition
            class="tw-duration-200"
            enter-from-class="tw-translate-y-[10px] tw-opacity-0"
            leave-to-class="tw-translate-y-[10px] tw-opacity-0"
            >
            <div v-if="isActive">
                <div
                @click="isActive = false"
                :class="[$root.$i18n.locale == 'ar' ? 'tw-left-0' : 'tw-right-0']"
                class="tw-w-[130px] tw-py-3 tw-space-y-2 tw-absolute tw-bottom-10 tw-shadow-sm tw-bg-white tw-rounded-lg tw-border tw-border-b-2"
                >
                <button
                    @click="$root.$i18n.locale = 'ar'"
                    class="tw-flex tw-items-center tw-gap-3 tw-px-3 hover:tw-bg-neutral-50 tw-w-full tw-py-1"
                >
                    <icon class="tw-text-2xl" icon="emojione:flag-for-morocco" />
                    <span>العربية</span>
                </button>
                <button
                    @click="$root.$i18n.locale = 'en'"
                    class="tw-flex tw-items-center tw-gap-3 tw-px-3 hover:tw-bg-neutral-50 tw-w-full tw-py-1"
                >
                    <icon
                    class="tw-text-2xl"
                    icon="emojione:flag-for-us-outlying-islands"
                    />
                    <span>English</span>
                </button>
                <button
                v-if="false"
                    @click="$root.$i18n.locale = 'fr'"
                    class="tw-flex tw-items-center tw-gap-3 tw-px-3 hover:tw-bg-neutral-50 tw-w-full tw-py-1"
                >
                    <icon class="tw-text-2xl" icon="emojione:flag-for-france" />
                    <span>Français</span>
                </button>
                </div>
            </div>
            </transition>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const isActive = ref(false);
const container = ref(null);

onClickOutside(container, () => (isActive.value = false));
</script>
