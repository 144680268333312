import {
    server
} from "@/api";


export const create = async (ad) => {
    return await server().post('api/ads', ad)
}

export const paginate = async (params) => {
    return await server().get('api/ads', { params });
}

export const all = async (params) => {
    return await server().get('api/ads', { params: { all: true, ...params } });
}

export const update = async (id, ad) => {
    return await server().post(`api/ads/${id}`, ad);
}

export const destroy = async (id) => {
    return await server().delete(`api/ads/${id}`);
}


export default {
    create,
    paginate,
    update,
    all,
    destroy
}