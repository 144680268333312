export const IS_LOCAL = false;
export const IS_PRODUCTION = true;

export const PRODUCTION_FRONTEND_URL  = IS_PRODUCTION ? "https://controldirective.net/" : "https://purepixeltv.com/";
export const PRODUCTION_BACKEND_URL   = IS_PRODUCTION ? "https://api.controldirective.net/" : "https://api.purepixeltv.com/";

export const DEVELOPMENT_FRONTEND_URL = "http://localhost:8080/";
export const DEVELOPMENT_BACKEND_URL  = "http://localhost:8000/";
export const NAWRIS_URL               = "https://portal.nawris.algoriza.com/";
export const PREFIX                   = "cod_";
export const DEFAULT_LANGUAGE         = "en";
export const CURRENCY                 = "LYD";

export const FRONTEND_URL = IS_LOCAL ? DEVELOPMENT_FRONTEND_URL : PRODUCTION_FRONTEND_URL;
export const BACKEND_URL  = IS_LOCAL ? DEVELOPMENT_BACKEND_URL  : PRODUCTION_BACKEND_URL;