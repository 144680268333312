<template>
    <div class="tw-relative tw-z-[15] tw-w-full" ref="container">
        <p class="tw-text-xs tw-mb-1 tw-font-semibold">{{ $t('fields.agent_id') }}</p>
        <button @click="visible = !visible"
            :class="[visible && '!tw-border-b']"
            class="tw-p-1.5 ltr:tw-pr-3 rtl:tw-pl-3 tw-cursor-pointer tw-w-full hover:tw-bg-gray-50 tw-duration-200 tw-border-b-2x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-smx tw-h-[32px] tw-flex tw-items-center">

            <div class="tw-flex tw-items-center tw-gap-2">
                <icon icon="solar:user-linear" class="tw-text-lgx tw-text-gray-400" />
                <p v-if="!selected.length" class="tw-text-xs tw-text-gray-700">{{ $t('fields.agent_id') }}</p>
                <div v-if="selected.length" class="tw-flex tw-gap- tw-divide-x rtl:tw-divide-x-reverse tw-overflow-hidden">
                    <p v-for="s in selected.slice(0,3)" :key="s" class="tw-text-[10px] tw-px-1 tw-text-gray-700 tw-whitespace-nowrap">{{ agents[s] }}</p>
                </div>
                <div v-if="selected.length > 3" class="tw-flex tw-gap- tw-divide-x tw-overflow-hidden">
                    <p class="tw-text-[10px] tw-px-1 tw-text-gray-700 tw-whitespace-nowrap">...</p>
                </div>
            </div>
        </button>


        <transition class="tw-duration-200" enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
            leave-to-class="tw-translate-y-[10px]  tw-opacity-0">
            <div v-if="visible" class="tw-duration-100">
                <div
                    class="tw-absolute tw-z-10 tw-top-[calc(100%+5px)] ltr:tw-left-0 rtl:tw-right-0 tw-flex tw-flex-col tw-w-[400px] tw-max-w-[200px] tw-h-[100px]x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-md">
                    <div class="tw-flex-1">
                        <div class="tw-flex tw-gap-2 tw-flex-wrap tw-p-2">
                            <div v-for="option in options" :key="option.id" role="button" @click="onClick(option)"
                                :class="[selected.includes(option.id) && '!tw-bg-primary-500 !tw-text-white !tw-border-primary-300']"
                                class="tw-h-[25px] tw-text-xs tw-px-2 hover:tw-bg-primary-50 tw-duration-200 hover:tw-border-primary-200 tw-rounded-sm tw-border tw-border-solid tw-border-gray-200 tw-w-fit tw-text-gray-500 tw-font-medium tw-flex tw-items-center">
                                {{ option.name }}</div>
                        </div>

                    </div>
                    <div v-if="false"
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button  @click="visible = false" intent="text" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{
                            $t('buttons.cancel') }}</a-button>
                        <a-button @click="onFilter" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.done')
                            }}</a-button>
                    </div>
                    <div
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button @click="visible = false" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.done')
                            }}</a-button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script setup>
import { computed } from 'vue';
import { ref, inject } from 'vue';
import { onClickOutside } from '@vueuse/core';
import users from '@/api/users';


const container = ref(null);
onClickOutside(container, () => visible.value = false);

const filter = inject('filter')
const filters = inject('filters')

const options = ref([])

const loading = ref(false);
const visible = ref(false);
const agents = ref({})
const selected = computed({
    get: () => filters.value.agent_id,
    set: v => filters.value.agent_id = v
});

const onClick = option => {
    if(selected.value.includes(option.id)) {
        selected.value = selected.value.filter(c => c != option.id)
    } else {
        selected.value.push(option.id)
    }
}

const getOptions = async () => {
    loading.value = true;
    await users.byRoles(['agent'])
    .then(
        res => {
            if(res.data.code == 'SUCCESS') {
                options.value = res.data.users
                options.value.map(c => agents.value[c.id] = c.name)
            }
        }
    )
    loading.value = false;
}

getOptions()

const onFilter = () => {
    filter();
    visible.value = false;
}

</script>

<style></style>