<template>
    <div class="tw-w-full tw-flex tw-text-center tw-justify-end tw-gap-2 tw-px-4">
        <a-action-button as="router-link" :to="{ name: 'pages.admin.products.edit', params: { id: product.id } }" icon="solar:pen-bold-duotone" intent="warning"></a-action-button>

        <a-action-button v-if="false" icon="solar:trash-bin-2-bold-duotone" intent="danger"></a-action-button>

    </div>
</template>

<script setup>
import { inject } from 'vue';

const product = inject('product')
</script>

<style>

</style>