<template>
    <div>
        <UserTable :loading="loading" :items="users" :options="options" />
    </div>
</template>

<script setup>
import { ref, provide } from 'vue';
import UserTable from './table/UserTable.vue'
import { admin } from '@/api/dashboard';

const loading = ref(true)
const users = ref([]);
const options = ref({});
provide('type', 'agent')

async function fetchUsers(page = 1) {
  loading.value = true;
  users.value = [];
  await admin.agents({ per_page: 10, page })
    .then(
      ({ data }) => {
        const { data: items, ...ops } = data.data;
        options.value = ops
        users.value = items;
      },
      error => {
        console.log(error)
      }
    )
  loading.value = false
}

fetchUsers()

</script>

<style>

</style>