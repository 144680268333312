<template>
    <div v-if="loading" class="tw-bg-white tw-p-2 tw-border tw-rounded tw-border-solid tw-border-gray-200 tw-min-h-[300px]">
        <div class="tw-flex tw-items-center tw-gap-2">
            <p class="tw-p-2 tw-font-bold tw-text-lg">{{ $t('fields.agent_status') }}</p>
            <p class="tw-px-1 tw-bg-gray-900 tw-text-white tw-text-sm tw-rounded"><icon icon="eos-icons:three-dots-loading" class="tw-text-xl" /></p>
        </div>

        <div class="tw-flex tw-gap-x">
            <div class="tw-w-[50%] tw-p-5 tw-h-[200px] tw-flex tw-items-center tw-justify-center">
                <div class=" tw-h-[180px] tw-p-2 tw-bg-gray-100 tw-rounded-full tw-aspect-square tw-animate-pulse">
                </div>
            </div>
            <div class="tw-w-[50%] tw-p-2 tw-h-[200px] tw-flex tw-justify-center tw-flex-col tw-gap-5">
                <div class="tw-w-[60%] tw-p-2 tw-bg-gray-100 tw-rounded-full tw-animate-pulse"></div>
                <div class="tw-w-[60%] tw-p-2 tw-bg-gray-100 tw-rounded-full tw-animate-pulse"></div>
                <div class="tw-w-[60%] tw-p-2 tw-bg-gray-100 tw-rounded-full tw-animate-pulse"></div>
            </div>
        </div>
    </div>
    <div v-else class="tw-bg-white tw-p-2 tw-border tw-border-solid tw-rounded tw-border-gray-200 tw-min-h-[300px]">
        <div class="tw-flex tw-items-center tw-gap-2">
            <p class="tw-p-2 tw-font-bold tw-text-lg">{{ $t('fields.agent_status') }}</p>
            <p class="tw-px-1 tw-bg-gray-900 tw-text-white tw-text-sm tw-rounded">{{ new Intl.NumberFormat().format(total)  }}</p>
        </div>
        <div class="tw-h-fullx tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
            <div class="tw-w-1/3x">
                <apexchart type="radialBar" height="250" class="" :options="options" :series="series"></apexchart>
            </div>
            <div class="tw-flex-1 tw-p-2 tw-flex tw-flex-col tw-gap-3 tw-justify-center">
                <div v-for="c in data.confirmations" :key="c" class="tw-flex tw-items-center tw-gap-2">
                    <div :class="[c.color]" class="tw-p-0 tw-px-1 tw-rounded tw-text-[12px] tw-font-bold tw-text-center tw-w-[42px]">{{ c.percentage }}%</div>
                    <p class="tw-text-sm tw-font-semibold">{{ $t(c.name) }} <span class="tw-text-xs">({{ new Intl.NumberFormat().format(c.count) }})</span></p>
                </div> 
            </div>

        </div>
    </div>
</template>

<script setup>
import { confirmation } from '@/api/analytics';
import { order_confirmations } from '@/config/status';
import { ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

const loading = ref(true);
const total = ref(0);
const i18n = useI18n();
const filters = inject('filters')
const register = inject('register')
// const data = ref([]);

const data = ref({
    confirmations: [
        // { name: 'Total', value: 450, percentage: '100%', color: '#000' },
        // { name: 'Confirmed', value: 225, percentage: '77%', color: '#10b981' },
        // { name: 'Double', value: 225, percentage: '77%', color: '#8b5cf6' },
        // { name: 'Canceled', value: 225, percentage: '77%', color: '#e11d48' },
        // { name: 'No Answer', value: 225, percentage: '77%', color: '#f59e0b' },
        // { name: 'Others', value: 225, percentage: '77%', color: '#64748b' },
    ]
})
const series = ref([77]);

const options = computed(() => ({
    chart: {
        type: 'radialBar',
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
                margin: 0,
                size: '70%',
                background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                }
            },
            track: {
                background: '#fff',
                strokeWidth: '67%',
                margin: 0,
                dropShadow: {
                    enabled: true,
                    top: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.35
                }
            },
            dataLabels: {
                show: true,
                name: {
                    offsetY: -10,
                    show: true,
                    color: '#888',
                    fontSize: '17px',
                    fontFamily: 'cairo',
                    fontWeight: 'medium',
                },
                value: {
                    formatter: function (val) {
                        return val + '%';
                    },
                    color: '#111',
                    fontFamily: 'cairo',
                    fontSize: '36px',
                    show: true
                }
            }
        }
    },
    fill: {
        type: 'gradient',
        colors: series.value[0] > 50 ? ['#10b981'] : ['#f43f5e'],
        gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: series.value[0] > 50 ? ['#22c55e', '#10b981'] : ['#f43f5e', '#e11d48'],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
        }
    },
    stroke: {
        lineCap: 'round'
    },
    labels: [i18n.t('common.confirmed')]
}));


const getData = async () => {
    loading.value = true;
    await confirmation(filters.value)
    .then(
        res => {
            handleData(res.data.data)
        },
        err => {
            console.log(err)
        }
    )
    loading.value = false
}

const handleData = dt => {
    const duplicate = dt.find(o => o.agent_status == 'duplicate')?.count

    total.value = dt.reduce((a, b) => a + b.count, 0) - (duplicate ? duplicate : 0); 

    const confirmed = dt.find(o => o.agent_status == 'confirmed')?.count
    series.value = [ confirmed > 0 ? ((confirmed / total.value) * 100).toFixed(1) : 0];

    data.value.confirmations = dt.map(
        i => {
            const c = order_confirmations.find(o => o.value == i.agent_status);

            if(c.value == 'duplicate') {
                return {...c, count: i.count, percentage: i.count > 0 ? ((i.count / (total.value + i.count)) * 100).toFixed(1) : 0 }
            }
            return {...c, count: i.count, percentage: i.count > 0 ? ((i.count / total.value) * 100).toFixed(1) : 0 }
        }
    )
}

getData();
register(() => getData())
</script>

<style>
/* Add any custom styles here */
</style>