<template>
  <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
      <router-link :to="{ name: 'pages.dashboard.admin' }" class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2"><icon icon="solar:home-smile-bold-duotone" /> <span class="tw-text-sm">{{ $t("dashboard.sidebar.dashboard") }}</span></router-link>
      <span class="tw-text-gray-300">/</span>
      <router-link :to="{ name: 'pages.admin.products' }" class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700"><span class="tw-text-sm">{{ $t("dashboard.sidebar.products") }}</span></router-link>
      <span class="tw-text-gray-300">/</span>
      <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2"><span class="tw-text-sm">{{ $t("buttons.new-product") }}</span></button>
  </div>
  <div class="tw-p-2">
      <div class="tw-h-full tw-w-full tw-flex tw-flex-col tw-p-4 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)]">
        <div class="tw-w-full">
          <h1 class="tw-border-primary-500 tw-border-solid" :class="[$root.$i18n.locale != 'ar' ? 'tw-border-l-2 tw-pl-2' : 'tw-border-r-2 tw-pr-2']">{{ $t('pages.products.create.product-informations') }}</h1>

          <div class="tw-mt-4 tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-2">
            <div class="tw-col-span-12 md:tw-col-span-6">
              <FormField
                  v-model="product.name"
                  
                  :label="$t('fields.product-name')"
                  type="text"
                  class=""
                  :error="errors.name && $t('errors.' + errors.name, { field: $t('fields.product-name') })"
                />
            </div>

            <div class="tw-col-span-12 md:tw-col-span-6">
              <FormField
                  v-model="product.sku"
                  input-dir="ltr"
                  :label="$t('fields.product-sku')"
                  type="text"
                  class=""
                  :error="errors.sku && $t('errors.' + errors.sku, { field: $t('fields.product-sku') })"
                />
            </div>

            <div class="tw-col-span-12 md:tw-col-span-12">
              <FormField
                  v-model="product.description"
                  
                  :label="$t('fields.description')"
                  type="textarea"
                  rows="3"
                  class=""
                  :error="errors.description && $t('errors.' + errors.description, { field: $t('fields.description') })"
                />
            </div>

            <div class="tw-col-span-12 md:tw-col-span-6">
              <FormField
                  v-model="product.buying_price"
                  left-icon="solar:tag-price-bold-duotone"
                  input-dir="ltr"
                  :label="$t('fields.buying-price')"
                  :error="errors.buying_price && $t('errors.' + errors.buying_price, { field: $t('fields.buying-price') })"
                  type="number"
                  class=""
                />
            </div>

            <div class="tw-col-span-12 md:tw-col-span-6">
              <FormField
                  v-model="product.selling_price"
                  left-icon="solar:tag-price-bold-duotone"
                  input-dir="ltr"
                  :label="$t('fields.selling-price')"
                  :error="errors.selling_price && $t('errors.' + errors.selling_price, { field: $t('fields.selling-price') })"
                  type="number"
                  class=""
                />
            </div>

            <div class="tw-col-span-12 md:tw-col-span-6">
              <FormField
                  v-model="product.video_url"
                  left-icon="solar:link-bold-duotone"
                  input-dir="ltr"
                  :label="$t('fields.video-url')"
                  :error="errors.video_url"
                  type="text"
                  class=""
                />
            </div>

            <div class="tw-col-span-12 md:tw-col-span-6">
              <FormField
                  v-model="product.store_url"
                  left-icon="solar:link-bold-duotone"
                  input-dir="ltr"
                  :label="$t('fields.store-url')"
                  :error="errors.store_url"
                  type="text"
                  class=""
                />
            </div>
              
              <div class="tw-col-span-12 md:tw-col-span-6 tw-grid tw-duration-200 tw-transition-all tw-ease-linear" :class="[!product.has_variants ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]']">
                <div class="tw-overflow-hidden">
                  <FormField
                      v-model.number="product.quantity"
                      left-icon="solar:box-minimalistic-bold-duotone"
                      input-dir="ltr"
                      :label="$t('fields.quantity')"
                      :error="errors.quantity"
                      type="number"
                      class=""
                    />
                </div>
            </div>

          </div>
        </div>

        <div class="tw-w-full tw-mt-5">
          <div class="tw-flex tw-items-center tw-gap-5 tw-justify-between">
            <div class="tw-gap-5 tw-flex tw-items-center">
              <h1 class="tw-border-primary-500 tw-border-solid" :class="[$root.$i18n.locale != 'ar' ? 'tw-border-l-2 tw-pl-2' : 'tw-border-r-2 tw-pr-2']">{{ $t('pages.products.create.product-variants') }}</h1>
              <button @click="product.has_variants = !product.has_variants" :class="[product.has_variants && '!tw-bg-primary-500']" class="tw-w-[46px] tw-bg-neutral-200 tw-p-0.5 tw-rounded-full tw-border tw-border-solid tw-duration-200">
                <div :class="[product.has_variants && ($root.$i18n.locale != 'ar' ? 'tw-translate-x-full' : '-tw-translate-x-full')]" class="tw-w-5 tw-h-5 tw-rounded-full tw-bg-white tw-border tw-border-b-2 tw-border-neutral-300 tw-duration-200"></div>
              </button>
            </div>

            
          </div>


          <div :class="[product.has_variants ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]']"
                class="tw-grid tw-duration-200 tw-transition-all tw-ease-linear">
                
                <div class="tw-mt-5 tw-overflow-hidden">
                  <ProductVariants v-if="product.has_variants" v-model:variants="variants" :errors="errors" />
                </div>

          </div>

        </div>

        <div class="tw-flex tw-flex-1 tw-items-end tw-justify-end tw-gap-2 tw-mt-5">
          <a-button as="router-link" :to="{ name: 'pages.admin.products' }" intent="simple" >{{ $t("buttons.return") }}</a-button>
          <a-button @click="onUpdate" :disabled="updating" :loading="updating" class="tw-px-2">{{ $t("buttons.update") }}</a-button>
        </div>
      </div>
  </div>
</template>

<script setup>
import FormField from "@/components/form/FormField";
import ProductVariants from "./partials/variants/ProductVariants.vue";
import { ref } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { get, update } from "@/api/products";
import useAlert from "@/composables/useAlert";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const product = ref({});
const errors = ref({});
const variants = ref([]);
const loading = ref(true);
const updating = ref(false);


const getData = async () => {
  loading.value = true;
  await get(route.params.id)
  .then(
    res => {
      if(res.data.code == 'SUCCESS') {
        product.value = res.data.product;
        variants.value = res.data.product.variants
      }
    }
  )
  loading.value = false;
}

const onUpdate = async () => {
  updating.value = true;
  product.value.variants = variants.value;
  await update(route.params.id, product.value)
  .then(
    res => {
      if(res.data.code == 'SUCCESS') {
        useAlert(i18n.t('messages.updated-successfully', { field: i18n.t('fields.product').toLocaleLowerCase() }),'success')
        router.push({ name: 'pages.admin.products' })
      }
    }
  )
  updating.value = false;
}

getData();
</script>

<style>

</style>