<template>
  <div ref="container" class="tw-relative">
    <button
      @click="isActive = !isActive"
      class="active:tw-translate-y-0.5 active:tw-border-b active:tw-shadow-none tw-p-1.5 tw-border tw-border-b-2 tw-border-solid tw-rounded-lg hover:tw-bg-neutral-50/10 hover:tw-shaxdow-md hover:tw-shadow-neutral-200 tw-duration-200"
    >
      <icon
        class="tw-text-2xl tw-text-neutral-600 tw-duration-200"
        icon="solar:earth-broken"
      />
    </button>

    <transition
      class="tw-duration-200"
      enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
      leave-to-class="tw-translate-y-[10px]  tw-opacity-0"
    >
      <div v-if="isActive">
        <div
          @click="isActive = false"
          :class="[$root.$i18n.locale == 'ar' ? 'tw-left-0' : 'tw-right-0']"
          class="tw-w-[130px] tw-py-3 tw-space-y-2 tw-absolute -tw-bottom-1 tw-translate-y-full tw-bg-white tw-rounded-lg tw-border tw-border-b-2"
        >
          <button
            @click="$root.$i18n.locale = 'ar'"
            class="tw-flex tw-items-center tw-gap-3 tw-px-3 hover:tw-bg-neutral-50 tw-w-full tw-py-1"
          >
            <icon class="tw-text-2xl" icon="emojione:flag-for-morocco" />
            <span>العربية</span>
          </button>
          <button
            @click="$root.$i18n.locale = 'en'"
            class="tw-flex tw-items-center tw-gap-3 tw-px-3 hover:tw-bg-neutral-50 tw-w-full tw-py-1"
          >
            <icon
              class="tw-text-2xl"
              icon="emojione:flag-for-us-outlying-islands"
            />
            <span>English</span>
          </button>
          <button
            v-if="false"
            @click="$root.$i18n.locale = 'fr'"
            class="tw-flex tw-items-center tw-gap-3 tw-px-3 hover:tw-bg-neutral-50 tw-w-full tw-py-1"
          >
            <icon class="tw-text-2xl" icon="emojione:flag-for-france" />
            <span>Français</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const isActive = ref(false);
const container = ref(null);

onClickOutside(container, () => (isActive.value = false));
</script>

<style>
</style>