<template>
    <div class="tw-relative tw-z-[16] tw-w-full" ref="container">
        <p class="tw-text-xs tw-mb-1 tw-font-semibold">{{ $t('fields.delivery_status') }}</p>
        <button @click="visible = !visible"
            :class="[visible && '!tw-border-b']"
            class="tw-p-1.5 ltr:tw-pr-3 rtl:tw-pl-3 tw-cursor-pointer tw-w-full hover:tw-bg-gray-50 tw-duration-200 tw-border-b-2x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-smx tw-h-[32px] tw-flex tw-items-center">

            <div class="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden">
                <icon icon="solar:delivery-linear" class="tw-text-lgx tw-text-gray-400" />
                <p v-if="!selected.length" class="tw-text-xs tw-text-gray-700">{{ $t('fields.delivery_status') }}</p>
                <div v-if="selected.length" class="tw-flex tw-gap- tw-divide-x rtl:tw-divide-x-reverse tw-overflow-hidden">
                    <p v-for="s in selected.slice(0, 2)" :key="s" class="tw-text-[10px] tw-px-1 tw-text-gray-700 tw-whitespace-nowrap">{{ $t(deliveries[s]) }}</p>
                </div>
                <div v-if="selected.length > 2" class="tw-flex tw-gap- tw-divide-x tw-overflow-hidden">
                    <p class="tw-text-[10px] tw-px-1 tw-text-gray-700 tw-whitespace-nowrap">...</p>
                </div>
            </div>
        </button>


        <transition class="tw-duration-200" enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
            leave-to-class="tw-translate-y-[10px]  tw-opacity-0">
            <div v-if="visible" class="tw-duration-100">
                <div
                    class="tw-absolute tw-z-10 tw-top-[calc(100%+5px)] ltr:tw-left-0 rtl:tw-right-0 tw-flex tw-flex-col tw-w-[400px] tw-h-[100px]x tw-bg-white tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-shadow-md">
                    <div class="tw-flex-1">
                        <div class="tw-flex tw-gap-2 tw-flex-wrap tw-p-2">
                            <div v-for="delivery in order_deliveries" :key="delivery.label" role="button" @click="onClick(delivery)"
                                :class="[selected.includes(delivery.value) && '!tw-bg-primary-500 !tw-text-white !tw-border-primary-300']"
                                class="tw-h-[25px] tw-text-xs tw-px-2 hover:tw-bg-primary-50 tw-duration-200 hover:tw-border-primary-200 tw-rounded-sm tw-border tw-border-solid tw-border-gray-200 tw-w-fit tw-text-gray-500 tw-font-medium tw-flex tw-items-center">
                                {{ $t(delivery.name) }}</div>
                        </div>

                    </div>
                    <div v-if="false"
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button  @click="visible = false" intent="text" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{
                            $t('buttons.cancel') }}</a-button>
                        <a-button @click="onFilter" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.filter')
                            }}</a-button>
                    </div>
                    <div
                        class="tw-p-2 tw-border-t tw-border-solid tw-border-gray-200 tw-flex tw-items-center tw-justify-end tw-gap-2">
                        <a-button @click="visible = false" class="!tw-w-[80px] !tw-h-[30px] !tw-min-h-0 tw-text-xs">{{ $t('buttons.done')
                            }}</a-button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script setup>
import { order_deliveries } from '@/config/status';
import { computed } from 'vue';
import { ref, inject } from 'vue';
import { onClickOutside } from '@vueuse/core';


const container = ref(null);
onClickOutside(container, () => visible.value = false);

const deliveries = ref({});
order_deliveries.forEach(c => {
    deliveries.value[c.value] = c.name
});


const filter = inject('filter')
const filters = inject('filters');


const visible = ref(false);
const selected = computed({
    get: () => filters.value.delivery_status,
    set: v => filters.value.delivery_status = v
});

const onClick = delivery => {
    if(selected.value.includes(delivery.value)) {
        selected.value = selected.value.filter(c => c != delivery.value)
    } else {
        selected.value.push(delivery.value)
    }
}

const onFilter = () => {
    filter();
    visible.value = false;
}

</script>

<style></style>