<template>
    <tr :key="item.id"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.name }}</td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ total }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ confirmed }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm tw-font-semibold" :class="[confirmation_rate > 50 ? 'tw-text-emerald-500' : 'tw-text-rose-500']">{{ confirmation_rate }}%</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">

            <div class="tw-whitespace-nowrap tw-text-center">
                {{ lastAction }}
            </div>
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRef, computed, ref, inject } from "vue";
import moment from 'moment'
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const props = defineProps(["item"]);
const item = toRef(props, "item");
const type = inject('type')

const confirmed = ref(type == 'agent' ? item.value.agent_orders.find(i => i.agent_status == 'confirmed')?.status_count : item.value.followup_orders.find(i => i.followup_status == 'reconfirmed')?.status_count );
const duplicate = ref(type == 'agent' ?  item.value.agent_orders.find(i => i.agent_status == 'duplicate')?.status_count : item.value.followup_orders.find(i => i.followup_status == 'duplicate')?.status_count );
const total = ref(type == 'agent' ?  item.value.agent_orders.reduce((p, c) => p + c.status_count, 0) - (duplicate.value ? duplicate.value : 0) : item.value.followup_orders.reduce((p, c) => p + c.status_count, 0) - (duplicate.value ? duplicate.value : 0));
const confirmation_rate = ref(confirmed.value > 0 ? ((confirmed.value / total.value) * 100).toFixed(2) : 0);

const lastAction = computed(() => {
    return item.value.last_action_at ? moment(item.value.last_action_at).locale(i18n.locale.value).fromNow() : '-'
})

</script>

<style></style>