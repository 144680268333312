<template>
  <tr
    :key="item.id"
    class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100"
  >
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.name }}</td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">

        <div class="tw-capitalize tw-whitespace-nowrap tw-text-center">
            {{ item.role }}
        </div>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">
        <div class="tw-p-1">
          <div>
            <label class="tw-flex tw-justify-between tw-items-center tw-p-0.5 xtw-h-[100px]">
              <input type="checkbox" :disabled="loading" v-model="is_active" class="tw-appearance-none tw-peer" />
              <span class="tw-cursor-pointer tw-w-10 tw-h-[22px] tw-flex tw-items-center tw-flex-shrink-0 tw-ml-4x tw-p-[4px] tw-bg-gray-100 tw-rounded-full after:!tw-w-4 after:!tw-h-4 after:tw-aspect-square after:tw-max-h-4 after:tw-bg-white after:tw-rounded-full after:tw-shadow-md
                peer-checked:tw-bg-primary-400 tw-duration-300 tw-ease-in-out after:tw-duration-300 peer-checked:after:tw-translate-x-4 rtl:peer-checked:after:-tw-translate-x-4 peer-disabled:tw-animate-pulse
              "></span>
            </label>
          </div>
        </div>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">
        <p class="tw-text-sm tw-whitespace-nowrap tw-text-center" v-if="item.last_login_at">{{ lastLogged }}</p>
        <p class="tw-text-sm tw-whitespace-nowrap tw-text-center" v-else>-</p>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-xs tw-text-center">
      <p class="tw-bg-gray-100 tw-text-gray-600 tw-rounded tw-aspect-square tw-w[20px] tw-h-[20px] tw-block tw-p-0.5 tw-mx-auto" >{{ item.login_times ?? 0 }}</p>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">
        <p class="tw-text-sm tw-whitespace-nowrap tw-text-center" v-if="item.last_action_at">{{ lastAction }}</p>
        <p class="tw-text-sm tw-whitespace-nowrap tw-text-center" v-else>-</p>
    </td>
    <td class="tw-px-5 tw-pr-0 tw-py-">
      <UserTableActions :item="item" />
    </td>
  </tr>
</template>

<script setup>
import { defineProps, toRef, computed, ref, watch } from "vue";
import moment from 'moment'
import UserTableActions from "./UserTableActions";
import { useI18n } from "vue-i18n";
import { toggle } from "@/api/users";
import useAlert from "@/composables/useAlert";

const i18n = useI18n();
const props = defineProps(["item"]);
const item = toRef(props, "item");
const is_active = ref(item.value.is_active);
const loading = ref(false)

watch(is_active, (val) => {
  console.log(val)
  toggleActive(val)
})

const lastLogged = computed(() => {
    return moment(item.value.last_login_at).locale(i18n.locale.value).fromNow()
})

const lastAction = computed(() => {
    return item.value.last_action_at ? moment(item.value.last_action_at).locale(i18n.locale.value).fromNow() : '-'
})

const toggleActive = async value => {
  loading.value = true;
  await toggle(item.value.id, value)
  .then(
    res => {
      if(res.data.code == 'SUCCESS') {
        useAlert(i18n.t("messages.updated-successfully", { field: i18n.t('common.user') }), "success")
      }
    }
  )
  loading.value = false;
}
</script>

<style></style>
