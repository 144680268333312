<template>
    <div>

        <a-action-button @click="visible = true" icon="solar:pen-bold-duotone" intent="warning"></a-action-button>

        <a-popup :visible="visible" @cancel="onCancel">
            <div
                class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px] tw-overflow-hidden tw-h-fit dark:tw-bg-gray-900 tw-bg-white tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-5 tw-rounded">
                <div
                    class="tw-p-4 tw-text-lg tw-border-b tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    {{ $t("titles.create-new-item") }}
                </div>

                <div class="tw-p-5 tw-bg-white">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">
                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:user-circle-bold-duotone" v-model="user.name" :label="$t('fields.name')" type="text" :error="errors.name &&
                                $t('errors.' + errors.name, {
                                    field: $t('fields.name'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:letter-bold-duotone" v-model="user.email" :label="$t('fields.email')" type="text" :error="errors.email &&
                                $t('errors.' + errors.email, {
                                    field: $t('fields.email'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField left-icon="solar:lock-keyhole-bold-duotone" v-model="user.password" :label="$t('fields.password')" :help="$t('common.leave_empty_to_not_change')" type="text" :error="errors.password &&
                                $t('errors.' + (errors.password?.includes(':') ? errors.password?.split(':')[0] : errors.password), {
                                    field: $t('fields.password'), number: errors.password?.split(':')[1],
                                })
                                " />
                        </div>

                        <div v-if="false" class="tw-col-span-12 md:tw-col-span-12">
                            <FormVueSelect left-icon="solar:shield-user-bold-duotone" v-model="user.role_id" option-value="id" :label="$t('fields.role')" type="select" :options="roles" :error="errors.role_id &&
                                $t('errors.' + errors.role_id, {
                                    field: $t('fields.role'),
                                })
                                " />
                        </div>


                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]"
                        intent="simple">{{
                            $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onUpdate"
                        class="tw-min-w-[120px]">{{
                            $t("buttons.update") }}</a-button>
                </div>
            </div>
        </a-popup>
    </div>
</template>

<script setup>
import { ref, computed, inject, defineProps } from "vue";
import FormField from "@/components/form/FormField";
import FormVueSelect from "@/components/form/FormVueSelect";
import { update } from "@/api/users";
import useAlert from "@/composables/useAlert";
import { useI18n } from "vue-i18n";
import { useRoleStore } from "@/stores";

const props = defineProps(['item'])
const i18n = useI18n()
const roleStore = useRoleStore();
const roles = computed(() => roleStore.roles);

const visible = ref(false);
const loading = ref(false);
const user = ref(structuredClone(props.item));
const errors = ref({});
const onUpdated = inject('onUpdated')

const onCancel = () => {
    user.value = { role_id: 1 };
    errors.value = {}
    visible.value = false;
};

const onUpdate = async () => {
    loading.value = true;
    errors.value = {}
    return await update(user.value.id, user.value)
        .then(
            r => {
                if (r.data.code == 'SUCCESS') {
                    useAlert(i18n.t('messages.updated-successfully', { field: i18n.t('fields.user') }))
                    onUpdated(r.data.user);
                    onCancel();
                }
            },
            error => {
                if (error.response.status == 422) {
                    for (let e in error.response.data.errors) {
                        errors.value[e] = error.response.data.errors[e][0];
                    }
                }
            }
        )
        .finally(
            () => loading.value = false
        )
};
</script>

<style></style>
