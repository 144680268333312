<template>
    <div>
        <a-action-button @click="visible = true" icon="solar:history-bold-duotone" intent="purple"></a-action-button>

        <transition>
            <div v-if="container">
                <order-history-popup v-model:visible="visible" :item="props.item" @cancel="onCancel" />
            </div>
        </transition>

    </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import OrderHistoryPopup from "@/components/orders/OrderHistoryPopup";

const props = defineProps({
    item: {
        required: true
    }
})
props
const visible = ref(false);

const container = ref(false);

const onCancel = () => {
    visible.value = false;
}

watch(() => visible.value, (newValue) => {

    if (!newValue) {
        setTimeout(() => container.value = false, 300)
    }

    if (newValue) {
        container.value = true
    }
});

</script>

<style></style>