import axios from "axios";
import { abort } from "./request";
import i18n from "@/plugins/i18n";
import useAlert from "@/composables/useAlert";
import router from "@/router";
import { useAuthStore } from "@/stores";

export const resolve = (response) => {
    return Promise.resolve(response);
}


export const reject = error => {

    const { t: translate } = i18n().global;

    if(axios.isCancel(error)) {
        return Promise.reject(error);
    }
    
    if(error?.response?.status == 401 && !location.href.includes('auth/login')) {
        abort();
        useAuthStore().logout();
        useAlert(translate('errors.session-expired'), 'danger');
        router.push({name: 'auth.login'});
    }

    if(error?.response?.status == 500) {
        if(error?.response?.data?.code == 'ERROR' && error?.response?.data?.error_message) {
            useAlert(translate(error?.response?.data?.error_message), 'danger')
        } else {
            useAlert(translate('errors.something-wrong-happened'), 'danger')
        }
    }

    return Promise.reject(error);
}