<template>
  <div class="tw-h-full">
    <div class="tw-w-full tw-h-full">
      <div class="tw-w-full tw-my-5 tw-px-5">
        <ul class="tw-flex tw-gap-5 tw-border-b">
          <li v-for="t in tabs" :key="t.key" @click="onClick(t)"
            :class="[t.key == tab.key ? '!tw-border-b-2 !tw-border-primary-500' : 'tw-border-b-2 tw-border-transparent']"
            class="tw-text-sm tw-pb-2 tw-font-semibold tw-border-b-2 tw-border-transparent tw-w-fit tw-duration-200 tw-cursor-pointer hover:tw-text-gray-600">
            {{ $t(t.name) }}
          </li>
        </ul>
      </div>
    </div>

    <div class="tw-w-full tw-overflow-hidden">
      <transition class="tw-duration-200" enter-from-class="tw-opacity-0 tw-translate-x-[10px]"
        leave-to-class="tw-opacity-0 -tw-translate-x-[10px]" mode="out-in">
        <component :is="tab.component" />
      </transition>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import AnalyticsTab from "./tabs/AnalyticsTab";
import OverviewTab from "./tabs/OverviewTab";
import UsersTab from "./tabs/UsersTab";
import { shallowRef } from "vue";

const route = useRoute();
const tabs = [
  {
    key: "overview",
    component: OverviewTab,
    name: "pages.dashboard.overview",
    icon: "ic:round-warehouse",
  },
  {
    key: "analytics",
    component: AnalyticsTab,
    name: "pages.dashboard.analytics",
    icon: "clarity:help-info-solid",
  },
  {
    key: "users",
    component: UsersTab,
    name: "pages.dashboard.users",
    icon: "dashicons:admin-links",
  },
];

let selected = tabs[0];

if (route.query?.tab) {
  selected = tabs.find(t => t.key == route.query?.tab) ?? tabs[0];
}

const tab = shallowRef(selected);

const onClick = t => {
  // const urlParams = new URLSearchParams(window.location.search);
  // urlParams.set('tab', t.key);
  
  // // Use history.replaceState to update the URL without reloading the page
  // const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  // history.replaceState(null, '', newUrl);
  
  tab.value = t;
};


</script>

<style></style>
