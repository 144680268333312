<template>
    <div>
        <FormVueSelect hide-errors multiple :searchable="false" v-model="selectedValues" max-height="200" class="!tw-h-fit"
            :options="deliveries" option-value="id" :label="$t('fields.delivery_id')" :error="errors.delivery_id &&
                $t('errors.' + errors.delivery_id, {
                    field: $t('fields.delivery_id'),
                })
                ">
            <template v-slot:option="{ option }">
                <p class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    option.label }}</p>
            </template>
            <template v-slot:selected-option-container="{ params }">
                <div class="vs__selected tw-text-xs tw-py-0 tw-px-2" :class="params.option.color" @click="removeValue(params.option.id)">
                    <p class="tw-max-w-[90px] tw-truncate">{{ params.option.label }}</p>
                    </div>
            </template>
        </FormVueSelect>
    </div>
</template>

<script setup>
import FormVueSelect from "@/components/form/FormVueSelect";
import { computed, ref, watch, inject } from 'vue';
import { useI18n } from "vue-i18n";
import { useStateStore } from '@/stores/app/state';

const i18n = useI18n();
const updateFilters = inject('update:filters')
const filters = inject('filters')
const stateStore = useStateStore();
const deliveries = computed(() => [{id: 0, label: i18n.t('common.without') }, ...stateStore.deliveries.map(p => ({ ...p, label: p.name }))]);

const errors = ref({})
const selectedValues = ref(filters.value.delivery_id);

watch(() => selectedValues.value, () => {
    const newFilters = {
        ...filters.value,
        delivery_id: selectedValues.value,
    }

    updateFilters(newFilters)
})

const removeValue = value => {
    selectedValues.value = selectedValues.value.filter(v => v != value)
}

</script>

<style></style>