import {
    create
} from "@/api/products";
import {
    ref
} from "vue";
import useAlert from "@/composables/useAlert";
import {
    useI18n
} from "vue-i18n";
import {
    useRouter
} from "vue-router";


export default (product, variants) => {

    const i18n = useI18n();
    const router = useRouter();
    const isFetching = ref(false);
    const errors = ref({});

    const success = async (response) => {

        if (response.status != 201) {
            return false;
        }

        useAlert(i18n.t('messages.created-successfully', { field: i18n.t('fields.product').toLocaleLowerCase() }),'success');

        router.push({ name: 'pages.admin.products' });
    }


    const failed = async (error) => {

        if (!error.response) return false;

        if (error.response.status == 422) {
            for (let e in error.response.data.errors) {
                errors.value[e] = error.response.data.errors[e][0];
            }
        }

        if (error.response.status == 429) {
            useAlert(i18n.t('errors.login-throttle-limit'), 'warning');
        }

        return false;
    }



    const execute = async () => {
        isFetching.value = true;
        product.value.variants = variants.value;
        await create(product.value)
            .then(success, failed)
        isFetching.value = false;
    }


    return {
        execute,
        isFetching,
        errors
    }

}