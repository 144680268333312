<template>
  <div class="tw-min-h-[700px]">
    <filter-wrapper @filter="onFilter" />

    <CardsWidget v-if="false" />

    <!-- <online-users-widget /> -->

    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-mt-2 tw-gap-2">
      <div class="tw-col-span-1 md:tw-col-span-6 lg:tw-col-span-5">
        <ConfirmationChart />
      </div>
      <div class="tw-col-span-1 md:tw-col-span-6 lg:tw-col-span-7">
        <DeliveryChart />
      </div>
    </div>
  </div>

</template>

<script setup>

import ConfirmationChart from '@/components/dashboard/admin/analytics/ConfirmationChart.vue';
import DeliveryChart from '@/components/dashboard/admin/analytics/DeliveryChart.vue';
// import OnlineUsersWidget from '@/components/dashboard/admin/users/OnlineUsersWidget.vue';
import FilterWrapper from '@/components/dashboard/admin/FilterWrapper.vue';
import CardsWidget from '@/components/dashboard/admin/analytics/CardsWidget.vue';
import { ref, provide } from 'vue';


const filters = ref({
  created_at: { from: null, to: null },
  treated_at: { from: null, to: null },
  dropped_at: { from: null, to: null },
  agent_status: [],
  agent_id: [],
  delivery_status: []
});

const callbacks = ref([]);

const onFilter = () =>  {
  callbacks.value.forEach(callback => callback());
} 

provide('filters', filters);
provide('register', callback => callbacks.value.push(callback));

</script>

<style></style>