import {
    server
} from "@/api";


export const admin = {
    agents: async (params) => {
        return await server().get('api/dashboard/admin/agents', { params })
    },

    followups: async (params) => {
        return await server().get('api/dashboard/admin/followups', { params })
    },

    overview: async (params) => {
        return await server().get('api/dashboard/admin/overview', { params })
    },
}

export default {
    admin
}