import {
    server
} from "@/api";


export const create = async (user) => {
    return await server().post('api/users', user)
}

export const paginate = async (params) => {
    return await server().get('api/users', { params });
}

export const all = async (params) => {
    return await server().get('api/users', { params: { all: true, ...params } });
}

export const byRoles = async (roles, params = {}) => {
    return await server().get('api/users/by-roles', { params: { roles, ...params } });
}

export const getTrackedTime = async (id, date, params = {}) => {
    return await server().get('api/users/' + id + '/sessions/' + date, { ...params });
}

export const getTrackedTimeByDays = async (id, params = {}) => {
    return await server().get('api/users/' + id + '/sessions', { ...params });
}

export const update = async (id, user) => {
    return await server().post(`api/users/${id}`, user);
}

export const destroy = async (id) => {
    return await server().delete(`api/users/${id}`);
}

export const toggle = async (id, value) => {
    return await server().post(`api/users/${id}/active`, { is_active: value });
}

export default {
    create,
    paginate,
    update,
    all,
    byRoles,
    destroy,
    getTrackedTime,
    getTrackedTimeByDays
}