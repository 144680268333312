import { order_confirmations, order_reconfirmations,  order_deliveries } from "@/config/status"

export const getConfirmation = (value) => {
    return order_confirmations.find(status => status.value === value)
}

export const getReconfirmation = (value) => {
    return order_reconfirmations.find(status => status.value === value)
}

export const getDelivery = (value) => {
    return order_deliveries.find(status => status.value === value)
}