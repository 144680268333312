import DashboardLayout from "@/layouts/dashboard/DashboardLayout";

import orders from "./orders"

export default [
  {
    path: "/followup",
    component: DashboardLayout,
    meta: {
      middleware: ["auth"],
      roles: ["followup"],
    },
    children: [
      ...orders
    ],
  },
];
